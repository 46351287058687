@import "~bootstrap/scss/bootstrap";
@import 'utils';

@font-face {
  font-family: Bernard;
  src: url(../fonts/bernc.ttf);
}

.navbar-brand {
  position: relative;
  .logo {
    font-family: Bernard, Times, Serif;
    text-transform: uppercase;
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
  .logo-portal
  {
    position: absolute;
    right:-10px;
    bottom:-18px;
  }
}

.default-layout {
  .container-fluid {
    menu {
      font-family: 'Montserrat', sans-serif;
      margin:0 !important;
      padding:0 !important;
      background: $color-bj-black;
      .collapse-menu {
        color: #ffffff;
        a {
          color:#fff;
        }
      }
      nav.navbar {
        &.bg-dark {
          background-color: $color-bj-black !important;
        }
      }
      .nav-link {
        color: #fff;
      }
      .nav-item.dropdown .nav-link {
        color: $color-bj-brown-light;
      }

      .title {
        color:$color-bj-brown-light;
      }
      .collapse-menu {
        background: transparent;
        position: relative;
        border: 0;
        margin-top:0;
        padding-top:0;
        padding-bottom:1rem;
        .dropdown-item-wrapper {
            line-height: 1.4rem;
          &.active a {
           color: $color-bj-brown-light !important;
          }
          .dropdown-item
          {
            white-space: normal;
            &:hover, &:focus {
              background: transparent;
            }
          }
        }
      }

    }
    main {
      width:100%;
      //background: grey;

    }
  }
}

.p-menu .p-menu-list {
  .p-submenu-header {
    padding:0.2rem;
  }
  .p-menuitem {
    .p-menuitem-link {
      padding: .6rem 1rem;
    }
    &:last-child .p-menuitem-link {
      padding-bottom: 1rem;
    }
  }
}


@media only screen and (min-width : map-get($grid-breakpoints, xs)) {
  nav.navbar {
    width: 100%;
    .navbar-brand {
      align-self: start;
    }
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, md)) {
  nav.navbar {
    width: 370px;
  }
}

.admin-main-layout {
  footer {
    display: block !important;
    div:first-child {
      display: none !important;
    }
  }
}

body footer {
  text-align: center;
  font-size: .8rem;
  a {
    color: black;
    &:hover {
      color: #404040;
    }
  }
}
.navbar footer {
  text-align: center;
  font-size: .8rem;
  color: #fff;
  a {
    color: white;
    &:hover {
      color: #c7c7c7;
    }
  }
}
.breadcrumb-comp {
  .breadcrumb {
    margin-bottom: 0;
    //margin-top:-1rem;
    background-color: transparent !important;
    //border-bottom:1px solid grey;
    border-radius: 0 !important;
    ol {
      border-radius: 0 !important;
    }
    a, a:hover {
      text-decoration: none;
      color: $color-bj-green;
    }
  }
  hr {
    margin-top:0rem;
  }
}
